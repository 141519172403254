
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { pInteger } from '@/utils/validate'
import { ToiletDetail } from '@/types/toilet.d'
import { ElForm } from 'element-ui/types/form'
import { Location } from '@/types/common.d'

@Component({
  components: { MapLocation }
})
export default class AddToilet extends Vue {
  @Prop({ default: false }) private isAdd!: boolean
  @Prop({ default: '' }) private toiletId?: string
  @Prop({ default: '' }) private projectId?: string

  @Watch('isAdd')
  onShowChange (value: boolean) {
    if (value) {
      this.addForm = this.initAddForm
      this.tDefaultDevice = null
      this.resourcesLocationList = []
      this.toiletId && this.getDetail()
    }
  }

  deviceList: Array<{ deviceId: string; deviceName: string }> = []
  tDefaultDevice: { deviceId: string; deviceName: string } | null = null
  resourcesLocationList: Array<Location> = []
  addForm: ToiletDetail = this.initAddForm
  isSubmit = false

  private rules = {
    projectId: [{ required: true, message: '请选择所属项目', trigger: 'change' }],
    toiletName: [{ required: true, message: '请输入厕所名称', trigger: 'change' }],
    manCubicleNum: [
      { required: true, message: '请输入厕所名称', trigger: 'change' },
      { validator: pInteger }
    ],
    womanCubicleNum: [
      { required: true, message: '请输入厕所名称', trigger: 'change' },
      { validator: pInteger }
    ],
    deviceId: [{ required: true, message: '请选择关联设备', trigger: 'change' }],
    latitude: [{ required: true, message: '请选择厕所位置', trigger: 'change' }]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  get initAddForm () {
    return {
      toiletName: '',
      projectId: '',
      manCubicleNum: '',
      womanCubicleNum: '',
      deviceId: '',
      longitude: '',
      latitude: ''
    }
  }

  getDeviceList () {
    this.$axios.get(this.$apis.toilet.selectNotBoundDeviceList, {
      projectId: this.addForm.projectId
    }).then(res => {
      this.deviceList = res || []
      // 如果是编辑的时候，且当前选择项目是厕所原本的项目则把原始绑定设备带入到设备列表
      if (this.tDefaultDevice && this.addForm.projectId === this.projectId) {
        this.deviceList.unshift(this.tDefaultDevice)
      }
    })
  }

  getDetail () {
    this.$axios.get<ToiletDetail>(this.$apis.toilet.selectSmartToiletDetail, {
      toiletId: this.toiletId
    }).then(res => {
      this.addForm = res
      if (this.addForm.deviceId) {
        this.tDefaultDevice = {
          deviceId: this.addForm.deviceId,
          deviceName: this.addForm.deviceName || this.addForm.deviceId
        }
      }
      this.resourcesLocationList = [{ latitude: res.latitude, longitude: res.longitude }]
      this.getDeviceList()
    })
  }

  // 项目变更
  projectChange () {
    this.addForm.deviceId = ''
    this.addForm.projectId && this.getDeviceList()
  }

  // 经纬度
  locationChange (arr: Array<Location>) {
    this.addForm.latitude = (arr[0] && arr[0].latitude) || ''
    this.addForm.longitude = (arr[0] && arr[0].longitude) || ''
  }

  onSubmit () {
    (this.$refs.addForm as ElForm).validate((valid) => {
      if (valid) {
        this.isSubmit = true
        const url = this.toiletId ? this.$apis.toilet.updateSmartToilet : this.$apis.toilet.insertSmartToilet
        this.$axios.post(url, this.addForm).then(() => {
          this.$message.success('保存成功')
          this.close()
          this.$emit('refreshData')
        }).finally(() => {
          this.isSubmit = false
        })
      }
    })
  }

  close () {
    // 数据清除
    (this.$refs.addForm as ElForm).resetFields()
    this.$emit('update:isAdd', false)
  }
}
